import React from 'react';
import NetlifyForm from 'react-netlify-form';
import { Heading, Text, Box, Flex, Button } from 'rebass';
import { Label, Input, Textarea, Radio, Checkbox } from '@rebass/forms';
import fiText from '../locales/fi.js';

const trigTagManager = () => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: 'formSubmission',
    formName: 'Lataa esite',
  });
  console.log('form sent to tag manager');
};

const NetlifyForm3 = props => {
  return (
    <NetlifyForm name="Lataa esite" action="">
      {({ loading, error, success }) => (
        <div>
          <div>
            <Box pb={1} mx="auto">
              <Flex mx="auto" flexDirection="column" maxWidth="400px">
                <Box width={1} mt={1} px={2} mb={0} className="required">
                  <Input
                    required
                    id="email"
                    name="email"
                    type="email"
                    placeholder="Sähköposti"
                  />
                </Box>
                <Box px={2} mb={0}>
                  <Button
                    mx="auto"
                    my={1}
                    type="submit"
                    color={'white'}
                    bg={'redDarkButton'}
                  >
                    {fiText.btnLataaEsite}
                  </Button>
                </Box>
              </Flex>
            </Box>
          </div>

          {loading && (
            <Text mt={-1} textAlign="right" fontStyle="italic">
              {fiText.wait}.
            </Text>
          )}
          {error && (
            <Text mt={-1} textAlign="right" fontStyle="italic">
              {fiText.downloadError}
            </Text>
          )}
          {success && (
            <div>
              <Text mt={0} textAlign="center" fontStyle="italic">
                <a
                  href={props.urlEsite}
                  target="_blank"
                  css={{ textDecoration: 'underline' }}
                >
                  {fiText.downloadEsite}
                </a>
              </Text>
              <Text mt={1} textAlign="center" fontStyle="italic">
                <a
                  href={props.urlEsiteSisustus}
                  target="_blank"
                  css={{ textDecoration: 'underline' }}
                >
                  {fiText.downloadEsiteSisustustyyli}
                </a>
              </Text>
              <Text
                mt={2}
                fontStyle="italic"
                maxWidth="720px"
                mx="auto"
                mb="20px"
              >
                Olemme lähettäneet tiedot sinulle myös sähköpostiin. Mikäli et
                saa viestiä muutaman minuutin kuluessa, tarkistathan
                roskapostikansiosi.
              </Text>
              {trigTagManager()}
            </div>
          )}
          <Text fontSize="15px" my="15px">
            Käsittelemme yhteystietojasi{' '}
            <a href="/tietosuojaseloste" target="_blank">
              Tietosuojaseloste
            </a>
            emme mukaisesti.
          </Text>
        </div>
      )}
    </NetlifyForm>
  );
};

export default NetlifyForm3;
