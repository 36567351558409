import React from 'react';
import styled from 'styled-components';
import { css } from 'styled-components/macro';
import BackgroundImage from 'gatsby-background-image';
import { Heading, Text, Box, Flex } from 'rebass';
import { LinkBtnGold, Btn } from '../components/Elements';
import Fade from 'react-reveal/Fade';
import scrollTo from 'gatsby-plugin-smoothscroll';

const BackgroundSection = ({
  className,
  title,
  lead,
  ctaText,
  ctaLink,
  imgHero,
  logo,
  heroLayer,
}) => {
  const red = heroLayer.red;
  const blue = heroLayer.blue;
  const green = heroLayer.green;
  const alpha = heroLayer.alpha / 256;

  return (
    <div
      css={`
        section {
          min-height: 100vh;
        }
        @media (max-width: 600px) {
          section {
            min-height: 400px;
            padding-top: 60px;
          }
        }
        @media (max-width: 900px) {
          section {
            max-height: 800px;
          }
        }
        .layer {
          position: absolute;
        }
      `}
    >
      <BackgroundImage
        Tag="section"
        className={className}
        fluid={imgHero.fluid}
        style={{ backgroundPosition: 'bottom' }}
      >
        <Box
          className="layer"
          sx={{
            justifyContent: 'center',
            alignItems: 'center',
            display: 'flex',
            flexDirection: 'column',
            textAlign: 'center',
            width: '100%',
            height: '100%',
            backgroundColor:
              'rgba(' + red + ',' + green + ',' + blue + ',' + alpha + ')',
          }}
        >
          <div>
            <img
              className="heroLogo"
              src={logo.url}
              alt={logo.alt}
              style={{ width: '280px', margin: '0 auto 1.4rem' }}
            />
            <Heading
              as="h1"
              my={5}
              fontWeight={600}
              maxWidth={'900px'}
              letterSpacing={'6px'}
              fontSize={['32px', '42px', '62px']}
              textAlign="center"
              sx={{
                textTransform: 'uppercase',
                textShadow: '0 0 10px rgba(0, 0, 0, 1)',
              }}
            >
              {' '}
              {title}{' '}
            </Heading>
            <Text
              className="hideOnMobile"
              fontSize="24px;"
              textAlign="center"
              mx="auto"
              maxWidth={'600px'}
              fontWeight={600}
              sx={{ textShadow: '0 0 8px rgba(0, 0, 0, 1)' }}
            >
              {' '}
              {lead}{' '}
            </Text>

            <Btn
              className="hoverOpacity hideOnMobile"
              onClick={() => scrollTo('#s1')}
              as="div"
              mx={'auto'}
              mb={4}
              mt={[4, 2]}
              color={'darkBlue'}
              bg={'white'}
            >
              Tutustu taloon
            </Btn>
          </div>
        </Box>
      </BackgroundImage>
    </div>
  );
};

export const Hero = styled(BackgroundSection)`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: #fff;
  width: 100%;
  height: 100%;
  background-position: bottom center;
  background-repeat: no-repeat;
  background-size: cover;
`;
