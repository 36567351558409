import React, { Fragment, useState, useEffect } from 'react';
import { graphql, useStaticQuery, Link } from 'gatsby';
import Img from 'gatsby-image';
import SEO from '../components/SEO';
import { Main, Section, Container, LinkBtnWhite } from '../components/Elements';
import HeaderFrontpage from '../components/HeaderFrontpage';
import FooterPre from '../components/FooterPre';
import { Hero } from '../components/Hero';
import { StyleSquare } from '../components/StyleSquare';
import { FourPoints } from '../components/FourPoints';
import { Heading, Flex, Box, Text, Image } from 'rebass';
import fiText from '../locales/fi.js';
import Header from '../components/Header';
import NetlifyForm3 from '../components/FormNetlify3';
import ImageGallery from '../components/ImageGallery';

const IndexPage = () => {
  const data = useStaticQuery(indexQuery);
  const {
    argumentti1H,
    argumentti1B,
    argumentti1Btn,
    argumentti1Img,
    argumentti2H,
    argumentti2B,
    argumentti2Img,
    argumentti2Btn,
    heroTitle,
    heroLead,
    heroCtaText,
    heroCtaLink,
    heroImg,
    heroLayer,
    miksiB,
    miksiH,
    seoMetaTags,
    sijaintiB,
    sijaintiBtn,
    sijaintiH,
    sijaintiImg,
    tilaratkaisuB,
    tilaratkaisuBtn,
    tilaratkaisuH,
    tilaratkaisuList1,
    tilaratkaisuList2,
    tilaratkaisuList3,
    tilaratkaisuList4,
    tyyliB,
    tyyliBtn,
    tyyliH,
    tyyliLammin,
    tyyliRaikas,
    tyyliSisustusTumma,
    tyyliSisustusUniikki,
  } = data.datoCmsEtusivu;
  const { logoValkoinen, logoGold } = data.datoCmsLogot;

  return (
    <Fragment>
      <SEO meta={seoMetaTags} />

      <Header />

      <Main bg={'blueDarkBg'}>
        <Hero
          title={heroTitle}
          lead={heroLead}
          ctaText={heroCtaText}
          ctaLink={heroCtaLink}
          imgHero={heroImg}
          logo={logoValkoinen}
          heroLayer={heroLayer}
        />

        <Section id="s1" bg={'blueDarkBg'} color={'beigeText'} py={[3, 5]}>
          <Container flexWrap="wrap">
            <Flex
              width={[1, 1 / 2]}
              my={[2, 7]}
              justifyContent="center"
              flexDirection="column"
            >
              <Heading fontSize={6}>{sijaintiH}</Heading>
              <Text my={2} maxWidth={360}>
                {sijaintiB}
              </Text>
              <Flex my={2}>
                <LinkBtnWhite to="/sijainti-ja-palvelut">
                  {sijaintiBtn}
                </LinkBtnWhite>
              </Flex>
            </Flex>
            <Flex width={[1, 1 / 2]} flexWrap="wrap" justifyContent="center">
              <Box width={'100%'} maxWidth={'536px'}>
                <Img
                  fluid={sijaintiImg.fluid}
                  alt={sijaintiImg.alt}
                  width={536}
                />
              </Box>
            </Flex>
          </Container>
        </Section>

        <Section bg={'beigeBg'} color={'blueDark'} py={[3, 5]}>
          <Container flexDirection={['column-reverse', 'row']}>
            <Flex
              pl={[1, 0]}
              width={[1, 1 / 2]}
              flexWrap="wrap"
              justifyContent="center"
              pl={[0, 1]}
            >
              <Box className="neliot" width={'100%'}>
                <Link to="/tyyli-ja-sisustus/raikas/">
                  <StyleSquare img={tyyliRaikas} title="Raikas" number={'1.'} />
                </Link>
                <Link to="/tyyli-ja-sisustus/lammin">
                  <StyleSquare img={tyyliLammin} title="Lämmin" number={'2.'} />
                </Link>
                <Link to="/tyyli-ja-sisustus/tumma">
                  <StyleSquare
                    mt={1}
                    img={tyyliSisustusTumma}
                    title="Tumma"
                    number={'3.'}
                  />
                </Link>
                <Link to="/tyyli-ja-sisustus/uniikki">
                  <StyleSquare
                    mt={1}
                    img={tyyliSisustusUniikki}
                    title="Uniikki"
                    number={'4.'}
                  />
                </Link>
              </Box>
            </Flex>
            <Flex
              width={[1, 1 / 2]}
              pl={[0, '8%']}
              my={2}
              justifyContent="center"
              flexDirection="column"
            >
              <Heading fontSize={6}>{tyyliH}</Heading>
              <Text my={2} maxWidth={360}>
                {tyyliB}
              </Text>
              <Flex my={2}>
                <LinkBtnWhite to="/tyyli-ja-sisustus">{tyyliBtn}</LinkBtnWhite>
              </Flex>
            </Flex>
          </Container>
        </Section>
        <Section bg={'blueDarkBg'} color={'beigeText'}>
          <Image
            mr={'-400px'}
            mt={'-400px'}
            width="870px"
            src={data.datoCmsSivusto.tausta1.url}
            alt={data.datoCmsSivusto.tausta1.alt}
            sx={{
              display: ['none', 'block'],
              position: 'absolute',
              top: 0,
              right: 0,
            }}
          />
          <Container
            className="cont_fourpoints"
            my={[6, 7]}
            flexDirection="column"
          >
            <Heading
              fontSize={6}
              textAlign="center"
              maxWidth={'720px'}
              mx="auto"
            >
              {tilaratkaisuH}
            </Heading>
            <Text my={5} mx={'auto'} textAlign="center" maxWidth={'720px'}>
              {tilaratkaisuB}
            </Text>
            <Box mx="auto" width={[1, 1, '66.7%']} maxWidth={'720px'}>
              <FourPoints text={tilaratkaisuList1} number={'1'} />
              <FourPoints text={tilaratkaisuList2} number={'2'} />
              <FourPoints text={tilaratkaisuList3} number={'3'} />
              <FourPoints text={tilaratkaisuList4} number={'4'} />
            </Box>
            <Flex my={2} justifyContent="center">
              <LinkBtnWhite to="/tilaratkaisu">{tilaratkaisuBtn}</LinkBtnWhite>
            </Flex>
          </Container>
        </Section>

        <Section bg={'beigeBg'} color={'blueDarkBg'} pt={[6, 7]} pb={[0, 5]}>
          <Image
            ml={'-400px'}
            mt={'-360px'}
            width="870px"
            src={data.datoCmsSivusto.tausta1.url}
            alt={data.datoCmsSivusto.tausta1.alt}
            sx={{
              display: ['none', 'block'],
              position: 'absolute',
              top: 0,
              left: 0,
            }}
          />
          <Container className="cont_why" flexDirection="column">
            <Heading mx="auto" fontSize={6} maxWidth={'720px'}>
              {miksiH}
            </Heading>
            <Text
              mt={3}
              mb={4}
              mx={'auto'}
              textAlign="center"
              maxWidth={'720px'}
            >
              {miksiB}
            </Text>
          </Container>
          <Container flexWrap="wrap">
            <Flex
              width={[1, 1 / 2]}
              justifyContent="center"
              flexDirection="column"
            >
              <Heading fontSize={6} maxWidth={443}>
                {argumentti1H}
              </Heading>
              <Text my={2} maxWidth={443}>
                {argumentti1B}
              </Text>
              <Flex my={2} mb={[7, 0]}>
                <LinkBtnWhite to="/tilaratkaisu">{argumentti1Btn}</LinkBtnWhite>
              </Flex>
            </Flex>
            <Flex width={[1, 1 / 2]} flexWrap="wrap" justifyContent="center">
              <Box width={'100%'}>
                <Img
                  fluid={argumentti1Img.fluid}
                  alt={argumentti1Img.alt}
                  width={736}
                />
              </Box>
            </Flex>
          </Container>
          <Container flexWrap="wrap" flexDirection={['column-reverse', 'row']}>
            <Flex width={[1, 1 / 2]} my={4} mt={[1, 0]}>
              <Box width={'100%'}>
                <Img
                  fluid={argumentti2Img.fluid}
                  alt={argumentti2Img.alt}
                  width={736}
                />
              </Box>
            </Flex>
            <Flex
              pl={[0, '8%']}
              width={[1, 1 / 2]}
              my={4}
              justifyContent="center"
              flexDirection="column"
            >
              <Heading fontSize={6} maxWidth={280}>
                {argumentti2H}
              </Heading>
              <Text my={2} maxWidth={443}>
                {argumentti2B}
              </Text>
              <Flex my={2}>
                <LinkBtnWhite to="/sijainti-ja-palvelut">
                  {argumentti2Btn}
                </LinkBtnWhite>
              </Flex>
            </Flex>
          </Container>
          <Container
            className="lataaEsiteLightBg"
            flexDirection="column"
            textAlign="center"
          >
            <Heading
              fontSize={6}
              textAlign="center"
              maxWidth={'720px'}
              mt={7}
              mx="auto"
            >
              {data.datoCmsLataaEsite.otsikko}
            </Heading>
            <Text maxWidth={630} mx={'auto'} mt={3} sx={{ zIndex: 1 }}>
              {data.datoCmsLataaEsite.teksti}
            </Text>

            <a
              href={data.datoCmsLataaEsite.esite.url}
              target="_blank"
              className="downloadLink"
              css={{ marginTop: 20, textDecoration: 'underline' }}
            >
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M15 9H19L12 16L5 9H9V3H15V9ZM5 20V18H19V20H5Z"
                  fill="#152A38"
                />
              </svg>
              Lataa kohde-esite (PDF)
            </a>
            <a
              href={data.datoCmsLataaEsite.esite2.url}
              target="_blank"
              className="downloadLink"
              css={{
                marginTop: 10,
                marginBottom: 50,
                textDecoration: 'underline',
              }}
            >
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M15 9H19L12 16L5 9H9V3H15V9ZM5 20V18H19V20H5Z"
                  fill="#152A38"
                />
              </svg>
              Lataa sisustustyylikooste (PDF)
            </a>
          </Container>
          <Container>
            <ImageGallery data={data.datoCmsHighlight.kuvagalleria} />
            {/*<NetlifyForm3
              urlEsite={data.datoCmsLataaEsite.esite.url}
              urlEsiteSisustus={data.datoCmsLataaEsite.esite2.url}
            />*/}
          </Container>
        </Section>
      </Main>
      <FooterPre />
    </Fragment>
  );
};

const indexQuery = graphql`
  {
    datoCmsLogot {
      logoValkoinen {
        url
        alt
      }
      logoGold {
        url
        alt
      }
    }
    datoCmsHighlight {
      kuvagalleria {
        image {
          url
        }
        width
        height
      }
    }
    datoCmsLataaEsite {
      otsikko
      teksti
      esite {
        url
      }
      esite2 {
        url
      }
    }
    datoCmsEtusivu {
      argumentti1H
      argumentti1B
      argumentti1Btn
      argumentti1Img {
        fluid(maxWidth: 536, imgixParams: { fm: "jpg", auto: "compress" }) {
          ...GatsbyDatoCmsFluid
        }
        alt
      }
      argumentti2H
      argumentti2B
      argumentti2Img {
        fluid(maxWidth: 536, imgixParams: { fm: "jpg", auto: "compress" }) {
          ...GatsbyDatoCmsFluid
        }
        alt
      }
      argumentti2Btn
      heroTitle
      heroLead
      heroCtaText
      heroCtaLink
      heroImg {
        fluid(maxWidth: 1200, imgixParams: { fm: "jpg", auto: "compress" }) {
          ...GatsbyDatoCmsFluid
        }
      }
      heroLayer {
        alpha
        blue
        green
        red
      }
      miksiB
      miksiH
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      sijaintiB
      sijaintiBtn
      sijaintiH
      sijaintiImg {
        fluid(maxWidth: 536, imgixParams: { fm: "jpg", auto: "compress" }) {
          ...GatsbyDatoCmsFluid
        }
        alt
      }
      tilaratkaisuB
      tilaratkaisuBtn
      tilaratkaisuH
      tilaratkaisuList1
      tilaratkaisuList2
      tilaratkaisuList3
      tilaratkaisuList4
      tyyliB
      tyyliBtn
      tyyliH
      tyyliLammin {
        fluid(maxWidth: 536, imgixParams: { auto: "compress" }) {
          ...GatsbyDatoCmsFluid
        }
        title
        alt
      }
      tyyliRaikas {
        fluid(maxWidth: 536, imgixParams: { auto: "compress" }) {
          ...GatsbyDatoCmsFluid
        }
        title
        alt
      }
      tyyliSisustusTumma {
        fluid(maxWidth: 536, imgixParams: { auto: "compress" }) {
          ...GatsbyDatoCmsFluid
        }
        title
        alt
      }
      tyyliSisustusUniikki {
        fluid(maxWidth: 536, imgixParams: { auto: "compress" }) {
          ...GatsbyDatoCmsFluid
        }
        title
        alt
      }
    }
    datoCmsSivusto {
      tausta1 {
        url
        alt
      }
      tausta3 {
        url
        alt
      }
      tausta4 {
        url
        alt
      }
    }
  }
`;

export default IndexPage;
